import { Stack, Typography } from "@mui/material";
import { useState } from "react";

import clsx from "classnames";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import colors from "../../../bliro-ui/src/theme/colors";
import { fontWeight } from "../../../bliro-ui/src/theme/fonts";
import PasswordChecker from "../../PaswordChecker";
import Required from "../Required";
import styles from "./PasswordInput.module.css";

interface IPasswordInputProps {
  label: string;
  requiredText?: string;
  defaultValue?: string;
  children?: React.ReactNode;
  isValid: boolean;
  showError: boolean;
  checker?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const PasswordInput = ({
  label,
  requiredText,
  defaultValue = "",
  children,
  onChange,
  isValid,
  showError,
  checker = false,
  onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {},
}: IPasswordInputProps) => {
  const [password, setPassword] = useState(defaultValue);
  const [isEyesOn, setIsEyesOn] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    onChange(event);
  };

  return (
    <>
      <Stack direction="column" gap={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="normalBody"
            fontWeight={fontWeight.semiBold}
            color={colors.dark[200]}
          >
            {label} <Required />
          </Typography>
          {children}
        </Stack>
        <Stack
          direction="row"
          gap={1}
          className={clsx(
            showError && isValid === false ? styles.required : "",
            "platform-input"
          )}
        >
          {isEyesOn ? (
            <>
              <input
                style={{ flex: 1 }}
                onChange={handleChange}
                className={styles.input}
                placeholder="********"
                type="text"
                name="password"
                onFocus={() => setIsFocused(true)}
                onKeyUp={onKeyUp}
              />
              <EyeOffIcon
                size={20}
                color={colors.dark[400]}
                className={styles.iconBtn}
                onMouseUp={() => setIsEyesOn(false)}
              />
            </>
          ) : (
            <>
              <input
                style={{ flex: 1 }}
                onChange={handleChange}
                className={styles.input}
                placeholder="********"
                type="password"
                name="password"
                onFocus={() => setIsFocused(true)}
                onKeyUp={onKeyUp}
              />
              <EyeIcon
                size={20}
                color={colors.dark[400]}
                className={styles.iconBtn}
                onMouseDown={() => setIsEyesOn(true)}
              />
            </>
          )}
        </Stack>
        {showError && isValid === false && (
          <Typography variant="smallBody" color={colors.red[100]}>
            {requiredText}
          </Typography>
        )}
      </Stack>
      {checker && isFocused && (
        <PasswordChecker password={password} confirmPassword={password} />
      )}
    </>
  );
};

export default PasswordInput;
