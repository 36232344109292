import { Stack, Typography } from "@mui/material";
import { CheckIcon, XIcon } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import colors from "../../bliro-ui/src/theme/colors";
import { fontWeight } from "../../bliro-ui/src/theme/fonts";
import { FormContext } from "../../context/FormContext";
import {
  isLowerCaseCharacter,
  isNumericCharacter,
  isSpecialCharacter,
  isUpperCaseCharacter,
} from "../../utils/validate";

interface IPasswordCheckItemProps {
  isValid: boolean;
  label: string;
}

interface IPasswordCheckerProps {
  password: string;
  confirmPassword: string;
}

const PasswordCheckItem = ({ isValid, label }: IPasswordCheckItemProps) => {
  return (
    <Stack direction="row" gap={0.5} alignItems="center ">
      {isValid ? (
        <CheckIcon size={16} color={colors.green[100]} />
      ) : (
        <XIcon size={16} color={colors.dark[500]} />
      )}
      <Typography
        variant="xSmallBody"
        fontWeight={fontWeight.regular}
        color={colors.dark[300]}
      >
        {label}
      </Typography>
    </Stack>
  );
};

const PasswordChecker = ({
  password,
  confirmPassword,
}: IPasswordCheckerProps) => {
  const formCtx = useContext(FormContext);
  const [isEightCharacters, setIsEightCharacters] = useState(false);
  const [containsLowerCase, setContainsLowerCase] = useState(false);
  const [containsUpperCase, setContainsUpperCase] = useState(false);
  const [containsNumber, setContainsNumber] = useState(false);
  const [containsSpecialCharacter, setContainsSpecialCharacter] =
    useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const fulfillsLowerCaseCondition = checkLowerCase(password);
    const fulfillsUpperCaseCondition = checkUpperCase(password);
    const fulfillsNumberCondition = checkNumber(password);
    const fulfillsSpecialCharacterCondition = checkSpecialCharacter(password);
    const fulfillsFourConditions = checkFourOfFourContainments(
      fulfillsLowerCaseCondition,
      fulfillsUpperCaseCondition,
      fulfillsNumberCondition,
      fulfillsSpecialCharacterCondition
    );
    const fulfillsEightCharacterCondition = checkEightCharacters(password);
    formCtx?.setIsValidPassword(
      fulfillsEightCharacterCondition && fulfillsFourConditions
    );
  }, [formCtx, password, confirmPassword]);

  const checkEightCharacters = (password: string) => {
    const fulfillsEightCharacterCondition = password.length >= 8;
    setIsEightCharacters(fulfillsEightCharacterCondition);
    return fulfillsEightCharacterCondition;
  };

  const checkFourOfFourContainments = (
    fulfillsLowerCaseCondition: boolean,
    fulfillsUpperCaseCondition: boolean,
    fulfillsNumberCondition: boolean,
    fulfillsSpecialCharacterCondition: boolean
  ) => {
    const count =
      Number(fulfillsLowerCaseCondition) +
      Number(fulfillsUpperCaseCondition) +
      Number(fulfillsNumberCondition) +
      Number(fulfillsSpecialCharacterCondition);
    const fulfillsFourConditions = count >= 4;
    return fulfillsFourConditions;
  };

  const checkLowerCase = (password: string) => {
    const lowerCaseCharacters = password.split("").some(isLowerCaseCharacter);
    setContainsLowerCase(lowerCaseCharacters);
    return lowerCaseCharacters;
  };

  const checkUpperCase = (password: string) => {
    const upperCaseCharacters = password.split("").some(isUpperCaseCharacter);
    setContainsUpperCase(upperCaseCharacters);
    return upperCaseCharacters;
  };

  const checkNumber = (password: string) => {
    const numericCharacters = password.split("").some(isNumericCharacter);
    setContainsNumber(numericCharacters);
    return numericCharacters;
  };

  const checkSpecialCharacter = (password: string) => {
    const specialCharacters = password.split("").some(isSpecialCharacter);
    setContainsSpecialCharacter(specialCharacters);
    return specialCharacters;
  };

  return (
    <Stack direction="row" gap={2} flexWrap="wrap" alignItems="center">
      <PasswordCheckItem
        label={t("signup:atLeastEightCharacters")}
        isValid={isEightCharacters}
      />
      <PasswordCheckItem
        label={t("signup:lowerCaseLetters")}
        isValid={containsLowerCase}
      />
      <PasswordCheckItem
        label={t("signup:upperCaseLetters")}
        isValid={containsUpperCase}
      />
      <PasswordCheckItem label={t("signup:numbers")} isValid={containsNumber} />
      <PasswordCheckItem
        label={t("signup:specialCharacters")}
        isValid={containsSpecialCharacter}
      />
    </Stack>
  );
};

export default PasswordChecker;
