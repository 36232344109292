import { Box } from "@mui/material";

import { Outlet } from "react-router-dom";
import styles from "./Layout.module.css";
import Header from "../Header";
import Testimonal from "../Testimonal";

interface IMainLayoutProps {
  testimonal?: boolean;
}

const MainLayout: React.FC<IMainLayoutProps> = ({
  testimonal = true,
}: IMainLayoutProps) => {
  return (
    <Box className={styles.container}>
      <Box className={styles.wrapper}>
        <Box className={styles.content}>
          <Header />
          <Box className={styles.contentBody}>{<Outlet />}</Box>
        </Box>
      </Box>
      {testimonal && (
        <Box className={styles.testimonal}>
          <Testimonal />
        </Box>
      )}
    </Box>
  );
};

export default MainLayout;
