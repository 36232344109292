import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import colors from "../../bliro-ui/src/theme/colors";
import { fontWeight } from "../../bliro-ui/src/theme/fonts";

import AppleLogo from "../../assets/logos/apple.png";
import GoogleLogo from "../../assets/logos/google.svg";
import MicrosoftLogo from "../../assets/logos/microsoft.svg";

import styles from "./Login.module.css";

import { useContext, useState } from "react";
import EmailInput from "../../components/inputs/EmailInput";
import PasswordInput from "../../components/inputs/PasswordInput";
import { FormContext } from "../../context/FormContext";
import {
  APP_HOST,
  CLIENT_ID,
  CUSTOM_AUTH0_API_DOMAIN,
  LAST_USED_PROVIDER_STORAGE_KEY,
  Provider,
} from "../../utils/constants";
import LastUsedWrapper from "../../Layout/LastUsedWrapper";
import useLocalStorageState from "use-local-storage-state";

const Login = () => {
  const { t } = useTranslation();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);

  const [, setLastUsedProvider] = useLocalStorageState(
    LAST_USED_PROVIDER_STORAGE_KEY,
    { defaultValue: "", storageSync: false }
  );

  const formCtx = useContext(FormContext);

  const isValidUsername = username !== "";
  const isValidPassword = password !== "";

  const handleProviderLogin = async (provider: Provider) => {
    setLastUsedProvider(provider);
    const url = `https://${CUSTOM_AUTH0_API_DOMAIN}/authorize?response_type=token&client_id=${CLIENT_ID}&connection=${provider}&redirect_uri=https://${APP_HOST}`;
    window.location.href = url;
  };

  const handleResetPassword = () => {
    const url = `https://${CUSTOM_AUTH0_API_DOMAIN}/u/reset-password/request/Username-Password-Authentication`;
    window.location.href = url;
  };

  const handleBliroLogin = async () => {
    if (!isValidUsername || !isValidPassword) {
      setShowError(true);
      return;
    }

    setLastUsedProvider(Provider.Email);
    // TODO: @vinitkhandal - please implement Username/Password login functionality here.
    console.log(`Attempting signup with ${username} and ${password}.`);
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") handleBliroLogin();
  };

  return (
    <Box className={styles.container}>
      <Stack direction="column" gap={2}>
        <Typography
          variant="h4"
          fontWeight={fontWeight.semiBold}
          color={colors.dark[200]}
        >
          {t("signup:login")}
        </Typography>
      </Stack>
      <Stack direction="column" gap={3} className={styles.content}>
        <Stack direction="column" gap={1}>
          <LastUsedWrapper provider={Provider.Google}>
            <Stack
              direction="row"
              alignContent="center"
              justifyContent="center"
              gap={1}
              className={styles.signUpBtn}
              onClick={() => handleProviderLogin(Provider.Google)}
            >
              <img className={styles.logo} alt="Google Logo" src={GoogleLogo} />
              <Stack direction="row" alignItems="center">
                <Typography
                  variant="normalBody"
                  fontWeight={fontWeight.regular}
                  color={colors.dark[200]}
                >
                  {t("signup:loginWithGoogle")}
                </Typography>
              </Stack>
            </Stack>
          </LastUsedWrapper>
          <LastUsedWrapper provider={Provider.Microsoft}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              gap={1}
              className={styles.signUpBtn}
              onClick={() => handleProviderLogin(Provider.Microsoft)}
            >
              <img
                className={styles.logo}
                alt="Microsoft Logo"
                src={MicrosoftLogo}
              />
              <Stack direction="row" alignItems="center">
                <Typography
                  variant="normalBody"
                  fontWeight={fontWeight.regular}
                  color={colors.dark[200]}
                >
                  {t("signup:loginWithMicrosoft")}
                </Typography>
              </Stack>
            </Stack>
          </LastUsedWrapper>
          <LastUsedWrapper provider={Provider.Apple}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              gap={1}
              className={styles.signUpBtn}
              onClick={() => handleProviderLogin(Provider.Apple)}
            >
              <img
                className={styles.appleLogo}
                alt="Apple Logo"
                src={AppleLogo}
              />
              <Stack direction="row" alignItems="center">
                <Typography
                  variant="normalBody"
                  fontWeight={fontWeight.regular}
                  color={colors.dark[200]}
                >
                  {t("signup:loginWithApple")}
                </Typography>
              </Stack>
            </Stack>
          </LastUsedWrapper>
        </Stack>
        <Box className={styles.orContainer}>
          <Divider />
          <Typography
            variant="normalBody"
            color={colors.dark[500]}
            className={styles.or}
          >
            {t("signup:or")}
          </Typography>
        </Box>
        <EmailInput
          label={t("signup:businessEmailAddress")}
          value={username}
          name="username"
          placeholder="john@businessaccount.com"
          onChange={handleChangeEmail}
          requiredText={t("signup:businessEmailAddressIsRequired")}
          showError={showError}
        />
        <PasswordInput
          label={t("signup:password")}
          defaultValue={formCtx?.password}
          onChange={handleChangePassword}
          onKeyUp={handleKeyUp}
          requiredText={t("signup:passwordIsRequired")}
          isValid={isValidPassword}
          showError={showError}
        >
          <Typography
            variant="smallBody"
            fontWeight={fontWeight.semiBold}
            color={colors.blue[100]}
          >
            <span
              style={{ textDecoration: "none" }}
              onClick={handleResetPassword}
            >
              {t("signup:forgotPassword")}
            </span>
          </Typography>
        </PasswordInput>
        <LastUsedWrapper provider={Provider.Email}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            onClick={handleBliroLogin}
          >
            <Stack direction="row" gap={1} alignItems="center">
              <span>{t("signup:login")}</span>
            </Stack>
          </Button>
        </LastUsedWrapper>
        <Stack direction="row" gap={1} mt={2}>
          <Typography
            variant="normalBody"
            fontWeight={fontWeight.semiBold}
            color={colors.dark[200]}
          >
            {t("signup:dontHaveAccount")}
          </Typography>
          <Typography
            variant="normalBody"
            fontWeight={fontWeight.semiBold}
            color={colors.dark[200]}
          >
            <Link style={{ textDecoration: "none" }} to="/">
              {t("signup:signUp")}
            </Link>
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Login;
