export const validateEmail = (email: string) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const validateText = (text: string) => {
  const textRegex = /^[a-zA-Z0-9\s]+$/;
  return textRegex.test(text);
};

export const specialCharacters = [
  " ",
  "!",
  '"',
  "#",
  "$",
  "%",
  "&",
  "'",
  "(",
  ")",
  "*",
  "+",
  ",",
  "-",
  ".",
  "/",
  ":",
  ";",
  "<",
  "=",
  ">",
  "?",
  "@",
  "[",
  "]",
  "\\",
  "^",
  "_",
  "`",
  "{",
  "|",
  "}",
  "~",
];

export const isEmptyCharacter = (c: string) => {
  return c === "";
};

export const isSpecialCharacter = (c: string) => {
  return specialCharacters.includes(c);
};

export const isNumericCharacter = (c: string) => {
  const textRegex = /^[0-9]$/;
  return textRegex.test(c);
};

export const isLowerCaseCharacter = (c: string) => {
  const textRegex = /^[a-z]$/;
  return textRegex.test(c);
};

export const isUpperCaseCharacter = (c: string) => {
  const textRegex = /^[A-Z]$/;
  return textRegex.test(c);
};
