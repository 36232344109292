import { Stack, Typography } from "@mui/material";
import clsx from "classnames";

import colors from "../../../bliro-ui/src/theme/colors";
import { fontWeight } from "../../../bliro-ui/src/theme/fonts";

import { validateEmail } from "../../../utils/validate";
import Required from "../Required";
import styles from "./EmailInput.module.css";

interface IEmailInputProps {
  name: string;
  label: string;
  requiredText?: string;
  value: string | undefined;
  placeholder: string;
  showError: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const EmailInput = ({
  name,
  label,
  value,
  placeholder,
  onChange,
  requiredText,
  showError,
}: IEmailInputProps) => {
  const isRequiredTextVisible =
    requiredText && showError && !validateEmail(value || "");

  return (
    <Stack direction="column" gap={1}>
      <Typography
        variant="normalBody"
        fontWeight={fontWeight.semiBold}
        color={colors.dark[200]}
      >
        {label} <Required />
      </Typography>
      <input
        name={name}
        className={clsx(
          "platform-input",
          isRequiredTextVisible && styles.required
        )}
        type="email"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
      {isRequiredTextVisible && (
        <Typography variant="smallBody" color={colors.red[100]}>
          {requiredText}
        </Typography>
      )}
    </Stack>
  );
};

export default EmailInput;
