import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useLocalStorageState from "use-local-storage-state";
import colors from "../../bliro-ui/src/theme/colors";
import { fontWeight } from "../../bliro-ui/src/theme/fonts";
import {
  LAST_USED_PROVIDER_STORAGE_KEY,
  Provider,
  providerToName,
} from "../../utils/constants";
import styles from "./LastUsedWrapper.module.css";
import { useEffect, useRef, useState } from "react";

interface LastUsedWrapperProps {
  provider: Provider;
  children: React.ReactNode;
}

const LastUsedWrapper = ({ children, provider }: LastUsedWrapperProps) => {
  const { t } = useTranslation();

  const isLastUsedProviderLoaded = useRef(false);

  const [providerStorageValue] = useLocalStorageState(
    LAST_USED_PROVIDER_STORAGE_KEY,
    {
      defaultValue: "",
      storageSync: false,
    }
  );

  const [lastUsedProvider, setLastUsedProvider] =
    useState(providerStorageValue);

  useEffect(() => {
    if (!isLastUsedProviderLoaded.current) {
      setLastUsedProvider(providerStorageValue);
      isLastUsedProviderLoaded.current = true;
    } else {
      setLastUsedProvider("");
    }
  }, [providerStorageValue]);

  if (provider !== lastUsedProvider) {
    return <>{children}</>;
  } else {
    return (
      <Stack direction="column" gap={0.5} className={styles.container}>
        <Box className={styles.wrapper}>{children}</Box>
        <Typography
          variant="smallBody"
          fontWeight={fontWeight.semiBold}
          color={colors.orange[100]}
          className={styles.content}
        >
          {t("signup:lastUsedToLoginByMethod", {
            method: providerToName(provider),
          })}
        </Typography>
      </Stack>
    );
  }
};

export default LastUsedWrapper;
