import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import BliroLogo from "../../bliro-ui/src/demo/logo";
import colors from "../../bliro-ui/src/theme/colors";
import { fontWeight } from "../../bliro-ui/src/theme/fonts";

const Confirm = () => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" p={11} justifyContent="center">
      <Stack direction="column" gap={2} width="428px" alignItems="center">
        <Stack direction="row" mb={4}>
          <BliroLogo width={115} height={32} />
        </Stack>
        <Typography
          variant="h4"
          fontWeight={fontWeight.semiBold}
          color={colors.dark[100]}
          textAlign="center"
        >
          {t("signup:verifyEmail")}
        </Typography>
        <Typography
          variant="subtitle2"
          fontWeight={fontWeight.semiBold}
          color={colors.dark[400]}
          textAlign="center"
        >
          {t("signup:accountCreationSuccess")}
          <br />
          {t("signup:toActivateYourAccount")}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Confirm;
