import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import colors from "../../bliro-ui/src/theme/colors";
import { fontWeight } from "../../bliro-ui/src/theme/fonts";

import AppleLogo from "../../assets/logos/apple.png";
import GoogleLogo from "../../assets/logos/google.svg";
import MicrosoftLogo from "../../assets/logos/microsoft.svg";

import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import useLocalStorageState from "use-local-storage-state";
import EmailInput from "../../components/inputs/EmailInput";
import NameInput from "../../components/inputs/NameInput";
import PasswordInput from "../../components/inputs/PasswordInput";
import { FormContext } from "../../context/FormContext";
import {
  APP_HOST,
  AUTH0_MANAGEMENT_API_DOMAIN,
  CLIENT_ID,
  CUSTOM_AUTH0_API_DOMAIN,
  LAST_USED_PROVIDER_STORAGE_KEY,
  policyUrl,
  Provider,
  termsUrl,
} from "../../utils/constants";
import { getCookie } from "../../utils/cookies";
import styles from "./SignUp.module.css";

declare global {
  interface Window {
    CelloAttribution: any; // You can replace `any` with the appropriate type if known
  }
}

const SignUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);

  const [, setLastUsedProvider] = useLocalStorageState(
    LAST_USED_PROVIDER_STORAGE_KEY,
    { defaultValue: "", storageSync: false }
  );

  const formCtx = useContext(FormContext);

  const [searchParams] = useSearchParams();

  const handleSetCelloUcc = useCallback(async () => {
    try {
      const celloUcc = await window.CelloAttribution("getUcc");
      if (celloUcc && typeof celloUcc === "string") {
        formCtx?.setCelloUcc(celloUcc);
      }
    } catch (error) {
      console.error("Error fetching Cello UCC:", error);
    }
  }, []);

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    formCtx?.setEmail(event.target.value);
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    formCtx?.setPassword(event.target.value);
  };

  const handleChangeFirstName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    formCtx?.setFirstName(event.target.value);
  };

  const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    formCtx?.setLastName(event.target.value);
  };

  const handleProviderSignup = async (provider: Provider) => {
    setLastUsedProvider(provider);

    const state = JSON.stringify({
      celloUcc: formCtx?.celloUcc,
      utm: formCtx?.utm,
    });
    const stateB64 = btoa(state);

    const url = `https://${CUSTOM_AUTH0_API_DOMAIN}/authorize?response_type=token&client_id=${CLIENT_ID}&connection=${provider}&redirect_uri=https%3A%2F%2F${APP_HOST}&state=${stateB64}`;
    window.location.href = url;
  };

  const handleSignup = async () => {
    if (
      !formCtx?.isValidEmail() ||
      !formCtx?.isValidName() ||
      !formCtx?.isValidPassword
    ) {
      setShowError(true);
      return;
    }

    setLastUsedProvider(Provider.Email);

    const url = `https://${AUTH0_MANAGEMENT_API_DOMAIN}/dbconnections/signup`;
    const email = formCtx?.email;
    const firstName = formCtx?.firstName;
    const lastName = formCtx?.lastName;
    const utmStr = JSON.stringify(formCtx?.utm);
    const utmB64 = btoa(utmStr);

    const name = `${firstName} ${lastName}`;
    try {
      await axios({
        url,
        method: "POST",
        data: {
          client_id: CLIENT_ID,
          connection: "Username-Password-Authentication",
          email: email,
          password: formCtx?.password,
          given_name: firstName,
          family_name: lastName,
          name: name,
          user_metadata: {
            celloUcc: formCtx?.celloUcc,
            utm: utmB64,
          },
        },
      });

      navigate("/confirm");
    } catch (err: any) {
      console.error(err);
      const message = err.response?.data?.description || err.message;
      toast.error(message);
    }
  };

  useEffect(() => {
    handleSetCelloUcc();

    if (
      searchParams.has("utm_source") ||
      searchParams.has("utm_medium") ||
      searchParams.has("utm_campaign") ||
      searchParams.has("utm_term") ||
      searchParams.has("utm_content")
    ) {
      const utmSource = searchParams.get("utm_source");
      const utmMedium = searchParams.get("utm_medium");
      const utmCampaign = searchParams.get("utm_campaign");
      const utmTerm = searchParams.get("utm_term");
      const utmContent = searchParams.get("utm_content");
      formCtx?.setUtm({
        utmSource: utmSource === "null" ? null : utmSource,
        utmMedium: utmMedium === "null" ? null : utmMedium,
        utmCampaign: utmCampaign === "null" ? null : utmCampaign,
        utmTerm: utmTerm === "null" ? null : utmTerm,
        utmContent: utmContent === "null" ? null : utmContent,
      });
    } else {
      const utmSource = getCookie("utm_source");
      const utmMedium = getCookie("utm_medium");
      const utmCampaign = getCookie("utm_campaign");
      const utmTerm = getCookie("utm_term");
      const utmContent = getCookie("utmContent");
      formCtx?.setUtm({
        utmSource: utmSource === "null" ? null : utmSource,
        utmMedium: utmMedium === "null" ? null : utmMedium,
        utmCampaign: utmCampaign === "null" ? null : utmCampaign,
        utmTerm: utmTerm === "null" ? null : utmTerm,
        utmContent: utmContent === "null" ? null : utmContent,
      });
    }
  }, [searchParams, handleSetCelloUcc]);

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") handleSignup();
  };

  return (
    <Box className={styles.container}>
      <Stack direction="column" gap={2}>
        <Typography
          variant="h4"
          fontWeight={fontWeight.semiBold}
          color={colors.dark[200]}
        >
          {t("signup:signUp")}
        </Typography>
      </Stack>
      <Stack direction="column" gap={2} className={styles.content}>
        <Stack direction="column" gap={1}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
            className={styles.signUpBtn}
            onClick={() => handleProviderSignup(Provider.Google)}
          >
            <img className={styles.logo} alt="Google Logo" src={GoogleLogo} />
            <Stack direction="row" alignItems="center">
              <Typography
                variant="normalBody"
                fontWeight={fontWeight.regular}
                color={colors.dark[200]}
              >
                {t("signup:signUpWithGoogle")}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
            className={styles.signUpBtn}
            onClick={() => handleProviderSignup(Provider.Microsoft)}
          >
            <img
              className={styles.logo}
              alt="Microsoft Logo"
              src={MicrosoftLogo}
            />
            <Stack direction="row" alignItems="center">
              <Typography
                variant="normalBody"
                fontWeight={fontWeight.regular}
                color={colors.dark[200]}
              >
                {t("signup:signUpWithMicrosoft")}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
            className={styles.signUpBtn}
            onClick={() => handleProviderSignup(Provider.Apple)}
          >
            <img
              className={styles.appleLogo}
              alt="Apple Logo"
              src={AppleLogo}
            />
            <Stack direction="row" alignItems="center">
              <Typography
                variant="normalBody"
                fontWeight={fontWeight.regular}
                color={colors.dark[200]}
              >
                {t("signup:signupWithApple")}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Box className={styles.orContainer}>
          <Divider />
          <Typography
            variant="normalBody"
            color={colors.dark[500]}
            className={styles.or}
          >
            {t("signup:or")}
          </Typography>
        </Box>
        <Stack direction="row">
          <Box className={styles.nameInputWrapper}>
            <NameInput
              label={t("signup:firstName")}
              value={formCtx?.firstName}
              placeholder="John"
              onChange={handleChangeFirstName}
              requiredText={t("signup:firstNameIsRequired")}
              showError={showError}
            />
          </Box>
          <Box className={styles.nameInputWrapper}>
            <NameInput
              label={t("signup:lastName")}
              value={formCtx?.lastName}
              placeholder="Doe"
              onChange={handleChangeLastName}
              requiredText={t("signup:lastNameIsRequired")}
              showError={showError}
            />
          </Box>
        </Stack>
        <EmailInput
          label={t("signup:businessEmailAddress")}
          value={formCtx?.email}
          name="username"
          placeholder="john@businessaccount.com"
          onChange={handleChangeEmail}
          requiredText={t("signup:businessEmailAddressIsRequired")}
          showError={showError}
        />
        <PasswordInput
          label={t("signup:password")}
          defaultValue={formCtx?.password}
          onChange={handleChangePassword}
          onKeyUp={handleKeyUp}
          requiredText={t("signup:passwordDoesNotMeetRequirements")}
          isValid={formCtx?.isValidPassword || false}
          showError={showError}
          checker={true}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSignup}
        >
          <span className={styles.signUpBtnText}>{t("signup:signUp")}</span>
        </Button>
        <Stack direction="row" gap={1} mt={2} mb={6}>
          <Typography
            variant="normalBody"
            fontWeight={fontWeight.semiBold}
            color={colors.dark[200]}
          >
            {t("signup:alreadyHaveAccount")}
          </Typography>
          <Typography
            variant="normalBody"
            fontWeight={fontWeight.semiBold}
            color={colors.dark[200]}
          >
            { /* TODO: to="/login" */}
            <Link
              style={{ textDecoration: "none" }}
              to={`https://${CUSTOM_AUTH0_API_DOMAIN}/authorize?response_type=token&client_id=${CLIENT_ID}&redirect_uri=https%3A%2F%2F${APP_HOST}`}
            >
              {t("signup:login")}
            </Link>
          </Typography>
        </Stack>
        <Box mb={7}>
          <Typography variant="smallBody" color={colors.dark[500]}>
            {t("signup:consent")}{" "}
            <a className={styles.link} href={termsUrl}>
              {t("signup:termsOfService")}
            </a>{" "}
            {t("signup:and")}{" "}
            <a className={styles.link} href={policyUrl}>
              {t("signup:privacyPolicy")}
            </a>{" "}
            {t("signup:consentEmail")}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default SignUp;
