import { ThemeProvider } from "@mui/material/styles";
import { Route, Routes } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SignUp from "./pages/SignUp";

import "./App.css";
import theme from "./bliro-ui/src/theme";
import { FormContextProvider } from "./context/FormContext";
import MainLayout from "./Layout/MainLayout";
import MainLayoutWithoutOutlet from "./Layout/MainLayoutWithoutOutlet";
import Confirm from "./pages/Confirm";
import Login from "./pages/Login";
import Verified from "./pages/Verified";
import LoginComplete from "./pages/LoginComplete";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h0: true;
    subtitle0: true;
    subtitle3: true;
    subtitle4: true;
    xxSmallTitle: true;
    xSmallTitle: true;
    smallTitle: true;
    normalTitle: true;
    largeTitle: true;
    xxSmallBody: true;
    xSmallBody: true;
    smallBody: true;
    normalBody: true;
    largeBody: true;
  }
}

function App() {
  return (
    <FormContextProvider>
      <div className="App">
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/" element={<MainLayout />}>
              <Route path="/" element={<SignUp />} />
              <Route path="/login" element={<Login />} />
            </Route>
            <Route path="/confirm" element={<Confirm />} />
            <Route
              path="/verified"
              element={
                <MainLayoutWithoutOutlet testimonal={false}>
                  <Verified />
                </MainLayoutWithoutOutlet>
              }
            />
            <Route path="/login-complete" element={<LoginComplete />} />
          </Routes>
          <ToastContainer
            position="bottom-left"
            transition={Slide}
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            limit={4}
          />
        </ThemeProvider>
      </div>
    </FormContextProvider>
  );
}

export default App;
