import { Box, Stack, Typography } from "@mui/material";
import { CheckIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import colors from "../../bliro-ui/src/theme/colors";
import { fontWeight } from "../../bliro-ui/src/theme/fonts";
import styles from "./LoginComplete.module.css";
import BliroLogo from "../../bliro-ui/src/demo/logo";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const LoginComplete = () => {
  const { t } = useTranslation();
  const [params] = useSearchParams();

  useEffect(() => {
    const code = params.get("code");
    if (code) {
      setTimeout(() => {
        window.location.href = `bliro-desktop://callback?code=${code}`;
      }, 1000);
    }
  }, [params]);

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        className={styles.checkMark}
      >
        <CheckIcon size={56} color={colors.orange[100]} />
      </Stack>
      <Typography
        variant="h2"
        fontWeight={fontWeight.semiBold}
        color={colors.dark[100]}
        textAlign="center"
        mt={4}
      >
        {t("signup:youAreSignedIn")}
      </Typography>
      <Typography
        variant="subtitle2"
        fontWeight={fontWeight.semiBold}
        color={colors.dark[400]}
        width="402px"
        textAlign="center"
        mt={2}
      >
        {t("signup:welcomeToBliro")}
      </Typography>
      <Box className={styles.divider} />
      <BliroLogo width={114} height={32} />
    </Stack>
  );
};

export default LoginComplete;
