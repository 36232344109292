import { Stack } from "@mui/material";

import BliroLogo from "../../bliro-ui/src/demo/logo";

interface IHeaderProps {}

const Header: React.FC<IHeaderProps> = () => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <BliroLogo width={115} height={32} />
    </Stack>
  );
};

export default Header;
