import { Box } from "@mui/material";

import Header from "../Header";
import Testimonal from "../Testimonal";
import styles from "./Layout.module.css";

interface IMainLayoutWithoutOutletProps {
  testimonal?: boolean;
  children: React.ReactNode;
}

const MainLayoutWithoutOutlet: React.FC<IMainLayoutWithoutOutletProps> = ({
  testimonal = true,
  children,
}: IMainLayoutWithoutOutletProps) => {
  return (
    <Box className={styles.container}>
      <Box className={styles.wrapper}>
        <Box className={styles.content}>
          <Header />
          <Box className={styles.contentBody}>{children}</Box>
        </Box>
      </Box>
      {testimonal && (
        <Box className={styles.testimonal}>
          <Testimonal />
        </Box>
      )}
    </Box>
  );
};

export default MainLayoutWithoutOutlet;
