import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import colors from "../../bliro-ui/src/theme/colors";
import { fontWeight } from "../../bliro-ui/src/theme/fonts";
import { ArrowRightIcon, CheckCircle2Icon } from "lucide-react";

const Verified = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Stack direction="column" gap={2}>
        <Stack direction="row" gap={1} alignItems="center">
          <CheckCircle2Icon size={56} color={colors.green[100]} />
          <Typography
            variant="h4"
            fontWeight={fontWeight.semiBold}
            color={colors.dark[200]}
          >
            {t("signup:emailVerified")}
          </Typography>
        </Stack>
        <Typography variant="largeBody" color={colors.dark[400]}>
          {t("signup:emailVerifiedDesc")}
        </Typography>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          onClick={() => {}}
        >
          <Stack direction="row" gap={1} alignItems="center">
            <span>{t("signup:goToBliroPlatform")}</span>
            <ArrowRightIcon size={20} color="white" />
          </Stack>
        </Button>
      </Stack>
    </Box>
  );
};

export default Verified;
