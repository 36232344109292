export const CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const CUSTOM_AUTH0_API_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const APP_HOST = process.env.REACT_APP_HOST;
export const AUTH0_MANAGEMENT_API_DOMAIN = process.env.REACT_APP_AUTH0_API_DOMAIN;

if (!CLIENT_ID || !CUSTOM_AUTH0_API_DOMAIN || !APP_HOST || !AUTH0_MANAGEMENT_API_DOMAIN) {
  throw new Error("Missing environment variables");
}

export const termsUrl = "https://www.bliro.io/terms-and-conditions";
export const policyUrl = "https://www.bliro.io/privacy-policy";

export const LAST_USED_PROVIDER_STORAGE_KEY = "lastUsedProvider";

export enum Provider {
  Google = "google-oauth2",
  Microsoft = "Microsoft",
  Apple = "Apple",
  Email = "Email",
}

export const providerToName = (provider: Provider) => {
  switch (provider) {
    case Provider.Google:
      return "Google";
    case Provider.Microsoft:
      return "Microsoft";
    case Provider.Apple:
      return "Apple";
    case Provider.Email:
      return "Email";
    default:
      return provider;
  }
}
