import { Stack, Typography } from "@mui/material";
import clsx from "classnames";

import colors from "../../../bliro-ui/src/theme/colors";
import { fontWeight } from "../../../bliro-ui/src/theme/fonts";

import styles from "./NameInput.module.css";
import Required from "../Required";
import { useState } from "react";

interface INameInputProps {
  label: string;
  value: string | undefined;
  placeholder: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  requiredText?: string;
  showError: boolean;
}

const NameInput = ({
  label,
  value,
  placeholder,
  onChange,
  requiredText,
  showError,
}: INameInputProps) => {
  const isRequiredTextVisible =
    requiredText && showError && value?.length === 0;

  return (
    <Stack direction="column" gap={1}>
      <Typography
        variant="normalBody"
        fontWeight={fontWeight.semiBold}
        color={colors.dark[200]}
      >
        {label} <Required />
      </Typography>
      <input
        className={clsx(
          "platform-input",
          styles.nameInput,
          isRequiredTextVisible && styles.required
        )}
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
      {isRequiredTextVisible && (
        <Typography variant="smallBody" color={colors.red[100]}>
          {requiredText}
        </Typography>
      )}
    </Stack>
  );
};

export default NameInput;
