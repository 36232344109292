import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { validateEmail, validateText } from "../utils/validate";


interface IUTM {
  utmSource: string | null,
  utmMedium: string | null,
  utmCampaign: string | null,
  utmTerm: string | null,
  utmContent: string | null
}
interface IFormContextData {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  isValidPassword: boolean;
  celloUcc: string | undefined;
  utm: IUTM;
  isValidEmail: () => boolean;
  isValidName: () => boolean;
  setEmail: Dispatch<SetStateAction<string>>;
  setPassword: Dispatch<SetStateAction<string>>;
  setConfirmPassword: Dispatch<SetStateAction<string>>;
  setFirstName: Dispatch<SetStateAction<string>>;
  setLastName: Dispatch<SetStateAction<string>>;
  setIsValidPassword: Dispatch<SetStateAction<boolean>>;
  setCelloUcc: Dispatch<SetStateAction<string | undefined>>;
  setUtm: Dispatch<SetStateAction<IUTM>>;
}

interface IFormContextProviderProps {
  children: React.ReactNode;
}

const FormContext = createContext<IFormContextData | null>(null);

const FormContextProvider = ({ children }: IFormContextProviderProps) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [isValidPassword, setIsValidPassword] = useState(false);

  const [celloUcc, setCelloUcc] = useState<string | undefined>(undefined);
  const [utm, setUtm] = useState<IUTM>({
    utmSource: null,
    utmMedium: null,
    utmCampaign: null,
    utmTerm: null,
    utmContent: null,
  });

  const isValidEmail = useCallback(() => {
    return validateEmail(email);
  }, [email]);

  const isValidName = useCallback(() => {
    return validateText(firstName) && validateText(lastName);
  }, [firstName, lastName]);

  const value = useMemo(() => {
    return {
      email,
      password,
      confirmPassword,
      firstName,
      lastName,
      isValidEmail,
      isValidName,
      isValidPassword,
      celloUcc,
      utm,
      setEmail,
      setPassword,
      setFirstName,
      setLastName,
      setConfirmPassword,
      setIsValidPassword,
      setCelloUcc,
      setUtm
    };
  }, [
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
    isValidEmail,
    isValidName,
    isValidPassword,
    celloUcc,
    utm,
    setIsValidPassword,
    setEmail,
    setPassword,
    setConfirmPassword,
    setFirstName,
    setLastName,
    setCelloUcc,
    setUtm
  ]);

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

export { FormContext, FormContextProvider };
