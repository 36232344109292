import { Box, Stack, Typography } from "@mui/material";
import { QuoteIcon } from "lucide-react";
import { useTranslation } from "react-i18next";

import colors from "../../bliro-ui/src/theme/colors";
import { fontWeight } from "../../bliro-ui/src/theme/fonts";
import avatarImg from "./images/avatar.png";
import styles from "./Testimonal.module.css";

const Testimonal: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box className={styles.container}>
      <Stack direction="column" gap={2} alignItems="flex-start">
        <QuoteIcon size={40} color={colors.orange[100]} strokeWidth={1} />
        <Typography className={styles.content}>
          “<span className={styles.highlight}>Bliro</span>{" "}
          {t("signup:testimonal.savesTimeDesc")} <br />
          {t("signup:testimonal.now")} <span className={styles.highlight}>Bliro</span>{" "}
          {t("signup:testimonal.gameChangerDesc")}”
        </Typography>
        <Stack gap={2} mt={3} direction="row" alignItems="center">
          <img alt="Maruska Kapic" src={avatarImg} />
          <Stack direction="column">
            <Typography
              variant="largeBody"
              fontWeight={fontWeight.semiBold}
              color={colors.dark[300]}
            >
              {t("signup:testimonal.name")}
            </Typography>
            <Typography variant="smallBody" color={colors.dark[400]}>
              <span>{t("signup:testimonal.role")}</span> <br />
              <span>@ Productsup</span>
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Testimonal;
